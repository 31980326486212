import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import EditTextComponent from "../../components/moap-components/EditTextComponent";
import config from "../../config/main.config";
//import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";


class InOwnNamePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textStore: {}
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'inOwnName'}
                    imageLinkId={"own-name-title-image-id"}
                    headerId={"own-name--title-header-id"}
                    subheaderId={"own-name--subtitle-header-id"}
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>STIFTUNG IM EIGENEN
                                NAMEN</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2><EditSimpleValueComponent textStore={this.state.textStore}
                                                      id={"own-name--title-sector-1-id"}
                                                      namespace={'inOwnName'}/>
                        </h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-bottom"}>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <p className={"text-block"}>
                            <EditHTMLComponent textStore={this.state.textStore}
                                               id={"own-name--icon-card-1-subheader-id"}
                                               namespace={'inOwnName'}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"}>
                        <Button circular fluid className={"call-to-action-button"}
                                onClick={() => {
                                    browserHistory.push("/contact");
                                    this.setState({});
                                }}>
                            {"Kontakt".toUpperCase()}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"}>
                        <Button circular fluid className={"call-to-action-button"}
                                onClick={() => {
                                    browserHistory.push("/dreps");
                                    this.setState({});
                                }}>
                            {"Dreps-Stiftung".toUpperCase()}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"}>
                        <Button circular fluid className={"call-to-action-button"}
                                onClick={() => {
                                    browserHistory.push("/stiftung-valentina");
                                    this.setState({});
                                }}>
                            {"Stiftung Valentina".toUpperCase()}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default InOwnNamePage